body {
  overflow-y: scroll;
  font-family: 'Roboto', sans-serif;
}

h1 {
  font-weight: 500;
}

h1.page-title {
  margin-top: 0px;
  margin-bottom: 10px;
  text-align:left;
  font-size: 3em;
}

h1.page-title a {
  text-decoration: none;
  font-weight: 500;
  color: #000;
}

.header-menu {
  text-align:left;
  margin-left: 0px;
}

.title {
  text-align: center;
}

.title.no-pad {
  padding-bottom: 0px;
}

/// Mixin to put items on a circle
/// [1] - Allows children to be absolutely positioned
/// [2] - Allows the mixin to be used on a list
/// [3] - In case box-sizing: border-box has been enabled
/// [4] - Allows any type of direct children to be targeted
/// 
/// @param {Integer} $nb-items - Number or items
/// @param {Length} $circle-size - Container size
/// @param {Length} $item-size - Item size
/// @param {String | false} $class-for-IE - Base class name for old IE
@mixin distribute-on-circle( 
  $nb-items,
  $circle-size,
  $item-size,
  $class-for-IE: false
) {
  $half-item: ($item-size / 2);
  $half-parent: ($circle-size / 2);
  
  position: relative; /* 1 */
  width:  $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%; 
  list-style: none; /* 2 */ 
  box-sizing: content-box; /* 3 */ 
  
  > * { /* 4 */
    display: block;
    position: absolute;
    top:  50%; 
    left: 50%;
    width:  $item-size;
    height: $item-size;
    margin: -$half-item;
  }
  
  $angle: (360 / $nb-items);
  $rot: -$angle;

  @for $i from 1 through $nb-items {
    @if not $class-for-IE {
      > :nth-of-type(#{$i}) {
        -moz-transform: rotate($rot * 1deg) translate($half-parent) rotate($rot * -1deg);
        -webkit-transform: rotate($rot * 1deg) translate($half-parent) rotate($rot * -1deg);
        transform: rotate($rot * 1deg) translate($half-parent) rotate($rot * -1deg);
      }
    } @else {
      > .#{$class-for-IE}#{$i} {
        // If CSS transforms are not supported
        $mt: sin($rot * pi() / 180) * $half-parent - $half-item;
        $ml: cos($rot * pi() / 180) * $half-parent - $half-item;
        margin: $mt 0 0 $ml;
      }
    }

    $rot: ($rot + $angle);
  }
}

.container {
  width: 1200px;
  margin: 0px auto;
  padding: 0 20px;
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.content-container {
  float:left;
  width: 100%;
}

.content-container-appear, .content-container-enter {
  opacity:0;
}

.content-container-enter-active, .content-container-appear-active {
  opacity: 1;
  transition: all 200ms;
}

.content-container-exit {
  opacity: 1;
}

.content-container-exit-active {
  opacity: 0;
  transition: all 200ms;
}

.diagram-container {
  width: 40%;
  float: left;
  position: sticky;
  position: -webkit-sticky; /* Safari */
  top: -10px;
}

.diagram-container h1 {
  background: #DDD;
  border-radius: 10px;
}

.article-container {
  width: 60%;
  float:left;
}

a {
  cursor: pointer;
}

.circle-container {
  @include distribute-on-circle(6, 340px, 130px, false); 
  margin: 8em auto 4em;
  width: 340px;
  position: relative;
}

.arrow-container {
  @include distribute-on-circle(6, 200px, 80px, false);
  width: 200px; 
  left: -35px;
  top: 135px;
  position: absolute;
}

.circle {
  background-color: #000;
  border-radius: 50%/50%;
  opacity: 1;
  width: 130px;
  height: 130px;
  z-index: 1;
  position: relative;
  -moz-transition: transform 0.15s linear;
  -webkit-transition: transform 0.15s;
  transition: transform 0.15s linear;
}

.circle.active, .circle:hover {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -moz-transition: transform .15s linear;
  -webkit-transition: transform .15s linear;
  transition: transform .15s linear;
}

.circle.inactive {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.circle.arrow {
  position:absolute;
  height: 80px;
  width: 80px;
  background-color: transparent;
  border: none;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.circle.center {
  -moz-transform: translate(0);
  -webkit-transform: translate(0);
  transform: translate(0);
  position: absolute;
  -moz-transition: transform 0.15s linear;
  -webkit-transition: transform 0.15s;
  transition: transform 0.15s linear;
}

.circle.center:hover {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.circle.center.active, .circle.center.active:hover {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -moz-transition: transform .15s linear;
  -webkit-transition: transform .15s linear;
  transition: transform .15s linear;
}

.circle.center a {
  color: #fff;
}

.circle-text{
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  font-weight: 500;
  color: #000;
  z-index: 3;
  width: 85%;
  height: 100%;
  margin: 0px auto;
  position: relative;
  display:table;
  //-moz-transform: scale(1);
  //-webkit-transform: scale(1);
  //transform: scale(1);
}

.circle-text-appear, .circle-text-enter {
  opacity:0;
}

.circle-text-enter-active, .circle-text-appear-active {
  opacity: 1;
  transition: all 200ms;
}

.circle-text-exit {
  opacity: 1;
}

.circle-text-exit-active {
  opacity: 0;
  transition: all 200ms;
}

.circle-links {
  display:table-cell;
  vertical-align: middle;
  height:100%;
  text-align: center;
  //-moz-transform: none;
  //-webkit-transform: none;
  //transform: none;
}

.circle-links a {
  color: #000;
  cursor: pointer;
  text-decoration: none;
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  z-index: 3;
  padding: 8px 0;
  display:block;
  //-moz-transform: none;
  //-webkit-transform: none;
  //transform: none;
}

.circle-links a:hover{
  color: #fFF;
  //-moz-transform: scale(1.0) translate(0);
  //-webkit-transform: scale(1.0) translate(0);;
  //transform: scale(1.0) translate(0);;
}

.circle.center p {
  padding: 6px 0;
  color: #fff
}

.header {
  margin-top: 1em;
  text-align:center;
}

.header li {
  display: inline-block;
  font-size: 18px;
  padding: 0px 20px 0px 0px;
  cursor: pointer;
}

.hexagon {
  position: relative;
  width: 173px; 
  height: 300px;
  left: 68px;
  background-color: #000;
}

.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  border-bottom: 150px solid transparent;
  border-top: 150px solid transparent;
}

.hexagon:before {
  right: 100%;
  border-right: 86.5px solid #000;
}

.hexagon:after {
  left: 100%;
  border-left: 86.5px solid #000;
  top: 0;
}

.hexagon.inner  {
  left: 0px;
  -webkit-transform: scale(.97, .97);
  -moz-transform: scale(.97, .97);
  transform: scale(.97, .97);
  background-color: #fff;
  border-color: #fff;
  z-index: 1;
  -moz-transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.hexagon.inner:before{
  border-right-width: 86.5px;
  border-right-style: solid;
  border-right-color: inherit;
  right: 99.7%;
}

.hexagon.inner:after{
  border-left-width: 86.5px;
  border-left-style: solid;
  border-left-color: inherit;
  left: 99.7%;
}

.icons {
  width: 80%;
  height: 80%;
  z-index: 2; 
  position: absolute; 
  margin: 10% 10%;
  //transform: rotate(45deg);
  opacity: 0.4;
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  filter: brightness(0.2);
}

.icons.small {
  opacity: 1;
}

.icons.arrow {
  height: 100%;
  width: 100%;
  margin: 0;
  z-index: 1;
  position: absolute;
  opacity: 1;
}

.article {
  font-family: 'PT Serif', serif;
  font-size: 1.1em;
  line-height: 1.6em;
  color: #000;
  margin: 0px auto;
  width: 97%;
  padding-left: 3%;
  padding-bottom: 100px;
  position:relative;
}

.article:empty {
  display:none;
}

.article h1,.article h2,.article h3,.article h4,.article h5 {      
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.article h1 {
  font-size: 2.2em;
}

.article h2 {
  font-size: 2.0em;
  line-height: 1.1em;
}

.article h3 {
  font-size: 1.7em;
  line-height:1.1em;
}

.article h4 {
  font-size: 1.4em;
  font-weight: 700;
  margin: 10px 0;
}

.article h5 {
  font-size: 1.1em;
  font-weight: 700;
  margin: 10px 0;
}

.article p.sig {
  text-align:right;
}

.article a {
  font-family: 'Roboto', 'sans-serif';
  text-decoration: none;
  color: red;
}

.article a:hover, .article a:visited:hover {
  color:darkred;
}

.article a:visited {
  color: purple;
}

.article.sig {
  text-align: right;
}

.article img {
  display: block;
  margin: 0px auto;
  width: 98%;
  border: 2px solid #000;
  border-radius: 10px;
}

.article img.right {
  float: right;
  width: 55%;
  margin-left: 2%;
}

.article img.left {
  float: left;
  width: 55%;
  margin-right: 2%;
}

.article img.img-pad-bottom {
  margin-bottom: 2%;
}

.article img.side-by-side {
  float: left;
  width: 47.3%;
  margin: 0px 1%;
}

.article ol {
  list-style-type: none;
  width: 90%;
  margin-left: -5px;
  counter-reset: li-counter;
}

.article ol li {
  position: relative;
  min-height: 2em;
  margin-bottom: 20px;
  padding: 8px 12px;
  background-color: #e9e9e9;
  border-radius: 8px;
}

.article ol li:before {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  font-size: 1.7em;
  top: 11px;
  left: -1.55em;
  text-align: right;
  width: 40px;

  content: counter(li-counter);
  counter-increment: li-counter;   
}

.article ol.sub {
  list-style-type: decimal;
  margin-left: 0px;
}

.article ol.sub li {
  position: relative;
  min-height: 1.2em;
  margin: 10px auto;
  padding: 0px;
  background-color: #e9e9e9;
  border-radius: 0px;
}

.article ol.sub li:before {
  width: 0px;
  content: none;
}

.article ul {
  padding-left: 20px;
}

.article ul li {
  padding-bottom: 10px;
}

.timeline ul {
  list-style: none;
  margin-left: 10px;
}

.timeline ul li {
  position: relative;
}

.timeline ul li:before {
  content:'';
  border: 7px #333 solid;
  position: absolute;
  margin-left: -25px;
  margin-top:7px;
  border-radius: 50%/50%;
}

.timeline ul li:after {
  content:'';
  position: absolute;
  height: 100%;
  z-index: -1;
  top: 12px;
  left: -19px;
  border-left: 3px #666 solid;
}

.timeline ul li:last-child:after {
  border-left: none;
}

.article blockquote {
  background: #CCC;
  padding: 1px 15px;
  border-radius: 10px;
  margin-left: 5%;
  margin-right: 5%;
}

.article blockquote.full {
  width: 95.5%;
  margin: 0px;
}

.article blockquote p.quote {
  font-style: italic;
}

.article blockquote p.bq-title{
  font-style: normal;
  font-weight: 700;
}

.article blockquote p.sig{
  font-style: normal;
  margin-top: -10px;
  text-align: right;
  font-weight: 700;
}

.article blockquote p.bq-key{
  font-style: normal;
}

.article blockquote p.sig:before{
  content:'-'
}

.article p.rsc-title {
  padding: 10px;
  background: #bbb;
  margin-bottom: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.article p.rsc-solo {
  padding: 10px;
  background: #bbb;
  border-radius: 8px;
}

.article p.rsc-solo a, 
.article p.rsc-desc a,
.article p.rsc-title a {
  font-family: 'Roboto', 'sans-serif';
  font-size: 1.1em;
  text-decoration: none;
}

.article p.rsc-solo a:hover, 
.article p.rsc-desc a:hover, 
.article p.rsc-title a:hover {
  color: darkred;
}

.article p.rsc-desc {
  padding: 10px;
  margin-top: 0;
  background: #eee;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.article p.rsc-title a {
  font-size: 1.1em;
}

.section {
  padding-bottom: 15px;
}

.section-appear, .section-enter {
  opacity:0;
}

.section-enter-active, .section-appear-active {
  opacity: 1;
  transition: all 200ms;
}

.section-exit {
  opacity: 1;
}

.section-exit-active {
  opacity: 0;
  transition: all 200ms;
}

span.back-btn {
  float:right;
}

.clear {
  clear: both; 
  display: block;
}

.tut-img.right {
  float:right;
  width: 55%;
  margin-left: 2%;
}

.tut-svg {
  float:left;
  height: 300px;
  width: 300px;
}

.art-svg {
  float:right;
  height: 64px;
  width: 64px;
}

.header-links:hover {
  color:red;
}

.header-links-active {
  font-weight: 700;
  color: #000;
  text-decoration: none;
}

.router-link {
  text-decoration:none;
  color: #000;
}

.sidebar-map a {
  font-family: "Roboto", "sans-serif";
  font-weight: 400;
  font-size: 1.2em;
  line-height: 0.2em;
  text-decoration: none;
  padding-left: 5px;
  color: #000;
}

.sidebar-map a:hover {
  color: red;
}

.sidebar-map a.active {
  font-weight: 700;
}

//Overrides for mobile devices (iPhone 375px width as ref)

@media only screen and (max-width: 375px) {
  .container {
    width: 98%;
    margin: 0px auto;
    padding: 0 1%;
    -moz-transition: all 0.5s linear;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
  }

  h1.page-title {
    margin-top: 0px;
    margin-bottom: 10px;
    text-align:center;
    font-size: 2.2em;
  }
  
  .diagram-container {
    display: block;
    width: 100%;
    top: 0;
    position: relative;
  
  }
  
  .article-container {
    display: block;
    width: 100%;
  }
  
  .circle-container {
    @include distribute-on-circle(6, 246px, 98px, false); 
    margin: 6em auto 2em;
    width: 246px;
    position: relative;
  }
  
  .arrow-container {
    @include distribute-on-circle(6, 160px, 60px, false);
    width: 200px; 
    left: -51px;
    top: 93px;
    position: absolute;
  }

  .circle {
    width: 98px;
    height: 98px;
  }
  
  .circle.arrow {
    height: 60px;
    width: 60px;
  }

  .circle-text {
    font-size: 0.7em;
  }
  
  .circle.center {
    -moz-transform: translate(0);
    -webkit-transform: translate(0);
    transform: translate(0);
    position: absolute;
    -moz-transition: transform 0.15s linear;
    -webkit-transition: transform 0.15s;
    transition: transform 0.15s linear;
  }

  .circle.center a {
    color: #fff;
  }

  .hexagon {
    position: relative;
    width: 125px; 
    height: 217px;
    left: 48px;
    background-color: #000;
  }
  
  .hexagon:before,
  .hexagon:after {
    content: "";
    position: absolute;
    border-bottom: 108.5px solid transparent;
    border-top: 108.5px solid transparent;
  }
  
  .hexagon:before {
    right: 100%;
    border-right: 62.5px solid #000;
  }
  
  .hexagon:after {
    left: 100%;
    border-left: 62.5px solid #000;
    top: 0;
  }
  
  .hexagon.inner  {
    left: 0px;
    -webkit-transform: scale(.96, .96);
    -moz-transform: scale(.96, .96);
    transform: scale(.96, .96);
    background-color: #fff;
    border-color: #fff;
    z-index: 1;
    -moz-transition: all 0.5s linear;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
  }
  
  .hexagon.inner:before{
    border-right-width: 62.5px;
    border-right-style: solid;
    border-right-color: inherit;
    right: 99.7%;
  }
  
  .hexagon.inner:after{
    border-left-width: 62.5px;
    border-left-style: solid;
    border-left-color: inherit;
    left: 99.7%;
  }

  .article {
    font-size: 1em;
    line-height: 1.6em;
  }

  .article h1 {
    font-size: 2.0em;
    line-height: 1.1em;
  }
  
  .article h2 {
    font-size: 1.8em;
    line-height: 1.1em;
  }
  
  .article h3 {
    font-size: 1.5em;
    line-height:1.1em;
  }
  
  .article h4 {
    font-size: 1.3em;
    font-weight: 700;
    margin: 10px 0;
  }

  .article h5 {
    font-size: 1.2em;
    font-weight: 700;
    margin: 10px 0;
  }


  .article img, .article img.right, .article img.left {
    display:block;
    float:none;
    margin: 1% auto 5%;
    width: 98%;
    border: 2px solid #000;
    border-radius: 8px;
  }

  .article ul {
    padding-left: 20px;
  }
  
  .article ul li {
    padding-bottom: 10px;
  }
  
  .article ol {
    list-style-type: none;
    margin-left: -22px;
    counter-reset: li-counter;
  }
  
  .article ol li {
    position: relative;
    min-height: 1.5em;
    margin-bottom: 20px;
    padding: 8px 12px;
    background-color: #e9e9e9;
    border-radius: 8px;
  }
  
  .article ol li:before {
    font-family: 'Roboto', sans-serif;
    position: absolute;
    font-size: 1.5em;
    top: 10px;
    left: -1.8em;
    text-align: right;
    width: 40px;
  
    content: counter(li-counter);
    counter-increment: li-counter;   
  }

  .timeline ul {
    list-style: none;
    margin-left: 10px;
  }
  
  .timeline ul li {
    position: relative;
  }
  
  .timeline ul li:before {
    content:'';
    border: 7px #333 solid;
    position: absolute;
    margin-left: -25px;
    margin-top:7px;
    border-radius: 50%/50%;
  }
  
  .timeline ul li:after {
    content:'';
    position: absolute;
    height: 100%;
    z-index: -1;
    top: 12px;
    left: -19px;
    border-left: 3px #666 solid;
  }
  
  .timeline ul li:last-child:after {
    border-left: none;
  }
  
  .article blockquote {
    margin-left: 5%;
    margin-right: 5%;
    background: #CCC;
    padding: 2px 10px;
    border-radius: 10px;
  }

  .tut-img.right {
    float: none;
    margin: 0px auto;
    display : block;
  }

  .tut-svg {
    float: none;
    width: 200px;
    height: 200px;
  }
}


/*

.circle-container.mini {
  @include distribute-on-circle(6, 150px, 50px, false); 
  margin: 10em auto 6em;
  width: 150px;
  position: relative;
}

.arrow-container.mini{
  @include distribute-on-circle(6, 50px, 20px, false); 
  width: 50px;
  position: absolute;
}

.circle.mini{
  border-radius: 50%/50%;
  opacity: 1;
  border: solid 2px #000;
  width: 50px;
  height: 50px;
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.hexagon.mini {
  position: relative;
  width: 76px; 
  height: 132px;
  left: 26px;
  background-color: #000;
}

.hexagon.mini:before,
.hexagon.mini:after {
  content: "";
  position: absolute;
  border-bottom: 66px solid transparent;
  border-top: 66px solid transparent;
}

.hexagon.mini:before {
  right: 100%;
  border-right: 38px solid #000;
}

.hexagon.mini:after {
  left: 100%;
  border-left: 38px solid #000;
  top: 0;
}

.hexagon.mini.inner  {
  left: 0px;
  -webkit-transform: scale(.96, .96);
  -moz-transform: scale(.96, .96);
  transform: scale(.96, .96);
  background-color: #fff;
  border-color: #fff;
  z-index: 1;
}

.hexagon.mini.inner:before{
  border-right-color: inherit;
  border-right-width: 37px; 
  border-right-style: solid;
  right: 99.8%;
}

.hexagon.mini.inner:after{
  border-left-color: inherit;
  border-left-width: 38.5px;
  border-left-style: solid;
  left: 99.8%;
}

.circle.mini.center {
  left: -38px;
  top: 65px;
}
*/